import React, {Fragment} from 'react';
import {Seo, Breadcrumb, ScrollTopBtn} from '../components';
import {
  HeaderOne,
  ServiceFour, 
  AboutSeven,
  TeamTwo,
  TestimonialThree,
  CtaOne,
  BlogFour,
  FooterOne,
  HeaderTwo,
  ProcessOne
} from '../containers';
import TechnologyStack from './TechnologyStack';
import WeDo from './WeDo';

const About = ()=> (
  <Fragment>
    <Seo title="About Us" />
    <WeDo  className="space-top space-extra-bottom"/>
    <TechnologyStack className="space-top space-extra-bottom"/>
    {/* <TeamTwo className="space-top space-extra-bottom"/> */}
    <ProcessOne className="space-top space-extra-bottom"/>
  </Fragment>
);

export default About;