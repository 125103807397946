import React, { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import { List, ScrollTopBtn, Seo, ServiceBoxOne } from "../../components";

// Service Data
import serviceData from "../../data/service.json";
import FooterOne from "../Footers/FooterOne";
import HeaderTwo from "../Headers/HeaderTwo";
import ServiceSingle from "./ServiceSingle";

const WebApplicationDevelopment = ({ ...restProps }) => (
  <Fragment>
    <Seo title={"Cizby - Web Application Development"} />
    <div {...restProps}>
      <br />
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-lg-12">
            <h2 className="h4">Web Application Development</h2>
            <p>
            Web application development refers to the creation of applications that run on the internet. These applications are designed to be accessible from anywhere in the world with an internet connection and can be used on a variety of devices, including desktop computers, laptops, tablets, and smartphones.
            </p>
            <p>
            Web applications are built using a combination of technologies, including HTML, CSS, JavaScript, and a server-side programming language such as Python, Ruby, or PHP. The backend code is executed on a web server and serves data to the frontend, which is displayed in a web browser.
            </p>
            <p>
            Web applications can be used for a wide range of purposes, from simple tasks like managing to-do lists to complex tasks like e-commerce and project management. The development process for a web application typically involves the following steps:
            </p>
            <div style={{ paddingLeft: "30px" }}>
              <List className="list-style3 align-center">
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Requirements gathering</b>: Understanding the client's needs and requirements for the application.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Design</b>: Creating a visual representation of the application and its functionality.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Development</b>: Writing the code for the application, including both the frontend and backend.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Testing</b>: Making sure the application works correctly and meets the client's requirements.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Deployment</b>: Making the application live and available to users.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Maintenance</b>: Updating and fixing any bugs or issues that arise.
                </List.Item>
              </List>
            </div>
            <p>
            Web applications have become increasingly popular in recent years, as they offer a cost-effective and scalable solution for businesses and organizations. They are also preferred over native applications due to their accessibility and ease of maintenance.
            </p>
            <p>
            In conclusion, web application development is a rapidly growing field that offers a wide range of opportunities for software developers, businesses, and organizations. With the right combination of technical skills and creativity, it is possible to create powerful and engaging applications that can help businesses achieve their goals.
            </p>
          </div>
        </div>
      </div>
    </div>
    <br/>
  </Fragment>
);

export default WebApplicationDevelopment;
