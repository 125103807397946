import React from 'react';
import {Link} from 'react-router-dom';
import {
  InfoMedia, 
  Widget, 
  AboutWidget, 
  CategoryWidget, 
  RecentPostWidget, 
  IconLink
} from '../../components/';
import menuLinks from '../../data/MenuLinks.json';
// Widget Datas
import widgetsdata from '../../data/widgets.json';
import serviceData from '../../data/service.json';
const {pageList, serviceList, recentPosts} = widgetsdata;


const FooterOne = ()=> (
  <footer className="footer-wrapper footer-layout1 background-image" style={{backgroundImage: 'url(images/shape/bg-footer-1-1.jpg)'}}>
    
    <div className="widget-area">
      <div className="container">
        <div className="row justify-content-between">            
          <div className="col-md-6 col-xl-auto">
            <Widget widgetTitle="Cizby Solutions LLP" className="footer-widget">
              <AboutWidget>
                <AboutWidget.Text>
                Door #4/461, 2nd Floor, Valamkottil Tower, Judgemuk, Kakkanad<br/>
                Ernakulam - 682021 <br/> 
                <i className="far fa-phone-alt"/> +917034377737 <br/>
                <i className="far fa-envelope"/> support@cizby.com
                </AboutWidget.Text>
                <IconLink className="footer-social">
                  <a target="_blank" icon="fab fa-facebook-f" href="https://www.facebook.com/Cizbysolutions" >
                    <i class="fab fa-facebook-f"/>
                  </a>
                  <a target="_blank" icon="fab fa-twitter" href="https://twitter.com/home">
                    <i class="fab fa-twitter"></i>
                  </a>
                  {/* <IconLink.Item icon="fab fa-behance" path="/"/> */}
                  <a target="_blank"  icon="fab fa-instagram" href="https://www.instagram.com/cizbysolutions/">
                    <i class="fab fa-instagram"></i>
                  </a>
                </IconLink>
              </AboutWidget>
            </Widget>
          </div>
          <div className="col-6 col-xl-auto">            
            <Widget widgetTitle="Links" className="widget_nav_menu footer-widget">
              <CategoryWidget>
                {menuLinks.map(cat => (
                  cat.path!="#" ?  <CategoryWidget.Item key={cat.name} path={cat.path}>{cat.name}</CategoryWidget.Item> : ''
                ))}
              </CategoryWidget>
            </Widget>
          </div>
          <div className="col-6 col-xl-auto">            
            <Widget widgetTitle="Top Services" className="widget_nav_menu footer-widget">
              <CategoryWidget>
                {serviceData.slice(0,5).map(cat => (
                  <CategoryWidget.Item key={cat.title} path={cat.path}>{cat.title}</CategoryWidget.Item>
                ))}
              </CategoryWidget>
            </Widget>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright-wrap">
      <div className="container">
        <p className="copyright-text">Copyright <i className="fal fa-copyright"/> 2023 <Link className="text-white" to="/">Cizby</Link>. All rights reserved by <a className="text-white" href="/">Cizby</a>.</p>
      </div>
    </div>    
  </footer>
);

export default FooterOne;