import React from 'react';
import {
  SecSubTitle,
  SecTitle,
  Button
} from '../../components';

const CtaOne = ({className})=> (
  <><br></br>
  <div className={`z-index-common background-image ${className || ''}`} style={{ backgroundImage: 'url("images/bg/faq-bg-1-1.jpg")'}}>
    <div className="container">
      <div className="row text-center text-lg-start align-items-center justify-content-between">
        <div className="col-lg-auto">
          <SecSubTitle className="text-white">We are here to answer your questions 24/7</SecSubTitle>
          <SecTitle className="cta-title1 h1">Need A Consultation?</SecTitle>
        </div>
        <div className="col-lg-auto">
          <Button onclick={() =>window.location = '/contact'}>Contact Us<i className="far fa-arrow-right"/></Button>
        </div>
      </div>
    </div>
  </div>
  <br></br>
  </>
);

export default CtaOne;