import React, { Fragment } from "react";
import { SecSubTitle, SecTitle, TitleWrap } from "../components";
const WeDo = ({ ...restProps }) => (
  <div {...restProps}>
    <div className="container">
      <TitleWrap className="text-center">
        <SecTitle className="h1 text-capitalize">We Do</SecTitle>
        <p>
          We provide on demand app development requirements for various
          verticals. On-demand Apps are giving rise to the business economy
          which is based on factors such as mobility and usefulness.
          Entrepreneurs/businesses are realizing the potentials from on-demand
          apps, as a result of which on-demand apps have become the mainstream
          in the industry.
        </p>
        <div className="row justify-content-center text-center">
          <div className="footer-media technolog-stack col-sm">
            <div>
              <SecSubTitle>ANDROID & IOS</SecSubTitle>
              <p>
                We have the best developers to build each application
                specifically for one mobile platform Android or iOS which can be
                easily installed through an application store, like Google Play
                or Apple's App.
              </p>
            </div>
          </div>
          <div className="col-sm-1"> </div>
          <div className="footer-media technolog-stack col-sm">
            <div>
              <SecSubTitle>WINDOWS</SecSubTitle>
              <p>
                We have the best developers to build desktop applications that
                target PCs running Windows by using platforms including the
                Universal Windows Platform (UWP), .NET, and the Windows API
                (Win32).
              </p>
            </div>
          </div>
          <div className="col-sm-1"> </div>
          <div className="footer-media technolog-stack col-sm">
            <div>
              <SecSubTitle>WEB</SecSubTitle>
              <p>
                We deliver complex, secure and well-documented backend solutions
                with resizable computing capacity that scale naturally within
                the industry’s best reliable platforms. Whether it’s a web app,
                custom backend system or an API, we create architectures that
                best suit your digital products.
              </p>
            </div>
          </div>
        </div>
      </TitleWrap>
    </div>
  </div>
);

export default WeDo;
