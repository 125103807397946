import React from 'react';
import {Link} from 'react-router-dom';

const Button = ({className, path, children, onclick, disabled})=> {  
  let Btn = <Link to={path || '#'} disabled={disabled} onClick={onclick} className={`vs-btn ${className || ''}`}>{children}</Link>

  if (!path) {
    Btn = <button onClick={onclick} disabled={disabled} className={`vs-btn ${className || ''}`}>{children}</button>
  }  
    
  return Btn;
};


export default Button;