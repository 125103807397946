import React, { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import { List, ScrollTopBtn, Seo, ServiceBoxOne } from "../../components";

// Service Data
import serviceData from "../../data/service.json";
import FooterOne from "../Footers/FooterOne";
import HeaderTwo from "../Headers/HeaderTwo";
import ServiceSingle from "./ServiceSingle";

const DevOpsAutomationService = ({ ...restProps }) => (
  <Fragment>
    <Seo title={"Cizby - DevOpsAutomation"} />
    <div {...restProps}>
      <br />
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-lg-12">
            <h2 className="h4">DevOps Automation</h2>
            <p>
            DevOps is an approach to culture, automation, and platform design intended to deliver increased business value and responsiveness through rapid, high-quality service delivery. DevOps practices bring development and operations team members together into a single DevOps team. This moves ideas and projects from development to production faster and more efficiently. DevOps involves more frequent changes to code and more dynamic infrastructure use when compared to traditional, manual management strategies.
            </p>
            <p>
            DevOps Automation refers to the use of software tools and processes to automate key tasks in the software development and operations (DevOps) lifecycle. The goal of DevOps automation is to improve efficiency, reduce errors and downtime, and increase the speed and quality of software delivery.
            </p>
            <p>
            Improves Collaboration: DevOps automation can help teams collaborate more effectively by breaking down silos between development, operations, and other teams. This can lead to better communication, faster problem resolution, and improved quality of software delivery.
            </p>
            <div style={{ paddingLeft: "30px" }}>
              <List className="list-style3 align-center">
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Improves Collaboration</b>: DevOps automation can help teams collaborate more effectively by breaking down silos between development, operations, and other teams. This can lead to better communication, faster problem resolution, and improved quality of software delivery.
                  </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Increases Agility</b>: DevOps automation enables teams to respond quickly to changing requirements, reducing the time required to make changes and deploy new software releases.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Enhances Security</b>: DevOps automation can help ensure that security processes and procedures are followed consistently, reducing the risk of security incidents and data breaches.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Reduces Costs</b>: By automating repetitive tasks, DevOps automation can reduce the cost of software development and operations, as well as minimize downtime and reduce the cost of fixing errors and issues.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Supports Compliance</b>: DevOps automation can help organizations meet regulatory and compliance requirements by ensuring that processes are followed consistently and that necessary records are maintained.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Enables Scalability</b>: DevOps automation makes it easier to scale software delivery processes as the business grows, enabling organizations to respond quickly to changing business needs.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Enhances Traceability</b>: DevOps automation can help organizations maintain a clear record of software development and delivery processes, making it easier to track changes and resolve issues.
                </List.Item>
              </List>
            </div>
            <p>Some common tasks that can be automated in a DevOps workflow include:</p>
            <div style={{ paddingLeft: "30px" }}>
              <List className="list-style3 align-center">
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Continuous Integration (CI)</b>: Automating the process of building, testing, and integrating code changes.
                  </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Continuous Deployment (CD)</b>: Automating the process of deploying software releases to different environments, such as production, staging, and development.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Configuration Management</b>: Automating the management of infrastructure configurations and the deployment of software and applications.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Infrastructure as Code (IaC)</b>: Automating the provisioning, configuration, and management of infrastructure resources using code.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Monitoring & Logging</b>: Automating the collection, analysis, and management of logs and metrics to monitor the health and performance of applications and systems.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Release Management</b>: Automating the management of software releases, including version control, branching, and rollback.
                </List.Item>
              </List>
            </div>
          </div>
          <div className="row gx-0 mb-4 pb-2 pt-3">
            <div className="col-xl-6"><img src="/images/purchased_image/Img1-software-developing.jpg" alt="project" className="w-100"/></div>
            <div className="col-xl-6">
              <div className="service-list-box">
                <h3 className="h5 title">Service Features</h3>
                <List className="list-style3">
                  <List.Item><i className="fal fa-check-circle"/>Infrastructure Automation</List.Item>
                  <List.Item><i className="fal fa-check-circle"/>Configuration Management</List.Item>
                  <List.Item><i className="fal fa-check-circle"/>Deployment Automation</List.Item>
                  <List.Item><i className="fal fa-check-circle"/>Performance Management</List.Item>
                  <List.Item><i className="fal fa-check-circle"/>Log management</List.Item>
                  <List.Item><i className="fal fa-check-circle"/>Monitoring</List.Item>
                  {/* <List.Item><i className="fal fa-check-circle"/>Resiliency and business continuity</List.Item> */}
                </List>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
    <br/>
  </Fragment>
);

export default DevOpsAutomationService;
