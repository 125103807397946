import React, { Fragment } from "react";
import { InfoMedia, SecSubTitle, SecTitle, TitleWrap } from "../components";

const TechnologyStack = ({ className }) => (
    <div className={`background-image ${className || ''}`} style={{backgroundImage: 'url(images/bg/process-bg-1-1.jpg)'}}>    
    <div className="container">
      <div className="row justify-content-center text-center">
        <div className="col-xl-6">
          <TitleWrap>
            <SecSubTitle>We Deal WIth</SecSubTitle>
            <SecTitle className="h1">Technology Stack</SecTitle>
          </TitleWrap>
        </div>
      </div>
      <div className="row justify-content-center text-center">
        <div className="footer-media technolog-stack col-sm-2">
            <InfoMedia
            icon="fab fa-css3-alt"
            />
        </div>
        <div className="footer-media technolog-stack  col-sm-2">
            <InfoMedia
            icon="fab fa-python"
            />
        </div>
        <div className="footer-media technolog-stack  col-sm-2">
            <InfoMedia
            icon="fab fa-html5"
            />
        </div>
        <div className="footer-media technolog-stack col-sm-2">
            <InfoMedia
            icon="fab fa-js"
            />
        </div>
        <div className="footer-media technolog-stack  col-sm-2">
            <InfoMedia
            icon="fab fa-php"
            />
        </div>
        <div className="footer-media technolog-stack col-sm-2">
            <InfoMedia
            icon="fab fa-java"
            />
        </div>
        </div>
        <div className="row justify-content-center text-center">        
            <div className="footer-media technolog-stack col-sm-2">
                <InfoMedia
                icon="fas fa-cloud"
                />
            </div>
            <div className="footer-media technolog-stack col-sm-2">
                <InfoMedia
                icon="fas fa-database"
                />
            </div>
      </div>
    </div>
  </div>
);

export default TechnologyStack;
