import React, {Fragment} from 'react';
import {Seo, Breadcrumb, ScrollTopBtn} from '../components';
import {
  HeaderOne,
  ServiceSingle,
  FooterOne,
  HeaderTwo
} from '../containers';

const ServiceDetails = ()=> (
  <Fragment>
    <Seo title="Services Details" />
    <HeaderTwo/>
    <ServiceSingle className="space-top space-extra-bottom"/>
    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default ServiceDetails;