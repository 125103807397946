import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";

// Home Pages
import HomeOne from '../pages/HomeOne';
import HomeTwo from '../pages/HomeTwo';
import HomeThree from '../pages/HomeThree';

// About Page
import About from '../pages/About';

// Blog Pages
import Blog from '../pages/Blog';
import BlogGrid from '../pages/BlogGrid';
import BlogDetails from '../pages/BlogDetails';

// Pricing Plans Page
import PricePlan from '../pages/PricePlan';

// Service Pages
import Service from '../pages/Service';
import ServiceDetails from '../pages/ServiceDetails';

// Team Pages
import Team from '../pages/Team';
import TeamDetails from '../pages/TeamDetails';

// Project Pages
import Project from '../pages/Project';
import ProjectDetails from '../pages/ProjectDetails';

// Contact Page
import Contact from '../pages/Contact';

// Not Fouond Page
import NotFound from '../pages/NotFound';
import Home from "../pages/Home";
import CustomSoftwareDevelopmentService from "../containers/Services/CustomSoftwareDevelopment";
import { FooterOne, HeaderTwo } from "../containers";
import { ScrollTopBtn } from "../components";
import WebApplicationDevelopment from "../containers/Services/WebApplicationDevelopment";
import MobileApplicationDevelopment from "../containers/Services/MobileAppDevelopment";
import CloudConsultingService from "../containers/Services/CloudColnsulting";
import DevOpsAutomationService from "../containers/Services/DevOpsAutomation";
import SoftwarePrototypingService from "../containers/Services/SoftwarePrototyping";


const AppRouters = () => (
  <Fragment>
    <HeaderTwo/>
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/about" exact element={<About/>} />
      <Route path="/#" exact element={<Service/>} />
      <Route path="/service/service-details" exact element={<ServiceDetails/>} />
      <Route path="/service/custon-software-development" exact element={<CustomSoftwareDevelopmentService/>} />
      <Route path="/service/web-application-development" exact element={<WebApplicationDevelopment/>} />
      <Route path="/service/mobile-application-development" exact element={<MobileApplicationDevelopment/>} />
      <Route path="/service/cloud-consulting" exact element={<CloudConsultingService/>} />
      <Route path="/service/dev-ops-automation" exact element={<DevOpsAutomationService/>} />
      <Route path="/service/software-prototyping" exact element={<SoftwarePrototypingService/>} />
      <Route path="/contact" exact element={<Contact/>} />
      <Route path="*" element={<NotFound/>} />
    </Routes>

    <FooterOne/>
    <ScrollTopBtn/>
  </Fragment>
);

export default AppRouters;