import React from 'react';
import {FeatureBoxOne} from '../../components/';
import Slider from 'react-slick';

import serviceData from '../../data/service.json';
const FeatureOne = ({className})=> {

  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    arrows: true,
    dots:true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {          
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {          
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {          
          slidesToShow: 1,
        }
      }
    ]
  };
  
  return (
    <div className={`feature-wrap1 ${className || ''}`}>
      <div className="container">   
        <Slider className="row" {...settings}> 
        {
          serviceData.map((slide, index) => 
            <FeatureBoxOne 
              title={slide.title}
              text={slide.text}
              image={slide.image}
              path={slide.path}
            />
          )
        }
        </Slider>
      </div>
    </div>
  );
}

export default FeatureOne;