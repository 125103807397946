import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import {InfoMedia, FormFive} from '../../components';

const ContactTwo = ({...restProps})=> (
  <div {...restProps}>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 mb-30">
          <div className="contact-box">
            {/* <h3 className="contact-box__title h4">Head Office Address</h3>                  
            <p className="contact-box__text">
            Some Content Here <br/> 
            </p> */}
            <InfoMedia
              icon="fal fa-phone-alt"
              title="Phone Number & Email"
              info={<><a href="tel:+917034377737">+91 70343 77737</a><a href="mailto:support@cizby.com">support@cizby.com</a></>}
            />
            <InfoMedia
              icon="far fa-map-marker-alt"
              title="Our Office Address"
              info="Door #4/461, 2nd Floor, valamkottil tower, Judgemuk, Kakkanad Ernakulam - 682021 "
            />
            <InfoMedia
              icon="far fa-clock"
              title="Official Work Time"
              info="7:00am - 6:00pm ( Mon - Fri ) Sat, Sun & Holiday Closed"
            />
          </div>
        </div>
        <div className="col-lg-6 mb-30">                
          <div className="contact-box">
            <h3 className="contact-box__title h4">Leave a Message</h3>
            <p className="contact-box__text">We’re Ready To Help You</p>
            <FormFive/>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ContactTwo;