import React, { useState, useEffect } from 'react';
import './index.css';

const SuccessMessage = (props) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`green-box${isVisible ? ' fade-in' : ' fade-out'}`}>
      {props.message}
    </div>
  );
};

export default SuccessMessage;
