import React, { useState } from 'react';
import {Button} from '../';
import axios from 'axios';
import SuccessMessage from '../common/success';

const FormFive = ()=> { 
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [successAPIMessage, setSuccessAPIMessage] = useState('');
  const [errorAPIMessage, setErrorAPIMessage] = useState('');
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);

  const formHandle = (event)=> {
    if(isSubmitBtnDisabled) return;
    setIsSubmitBtnDisabled(true)
    event.preventDefault();
    if (validateForm()) {
      axios.post('https://jzgqdhdrfh.execute-api.ap-south-1.amazonaws.com/cizby/contact-us', {
        name: name,
        email: email,
        message: message
      })
      .then(function (response) {
        console.log(response);
        setSuccessAPIMessage('Thank you for contactin us. We will get back to you soon.');
        setTimeout(() => {
          setSuccessAPIMessage('')
        }, 1000 * 10)
        setIsSubmitBtnDisabled(false)
      })
      .catch(function (error) {
        console.log(error);
        setIsSubmitBtnDisabled("")
        setErrorAPIMessage('There are some internal error happend. Please try again after sometimes.');
        setTimeout(() => {
          setErrorAPIMessage(false)
        }, 1000 * 10)
      });
    }
    else setIsSubmitBtnDisabled(false)
  } 

  const validateForm = () => {
    let valid = true;
    if (name.trim() === '') {
      setNameError('Please enter your name.');
      valid = false;
    } else {
      setNameError('');
    }

    if (email.trim() === '') {
      setEmailError('Please enter your email address.');
      valid = false;
    } else if (!isValidEmail(email)) {
      setEmailError('Please enter a valid email address.');
      valid = false;
    } else {
      setEmailError('');
    }

    if (message.trim() === '') {
      setMessageError('Please enter your message.');
      valid = false;
    } else {
      setMessageError('');
    }

    return valid;
  }

  const isValidEmail = (email) => {
    // This is a basic email validation function - it's not perfect, but it should catch most invalid email addresses.
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  return (
    <form action="#" className="vs-contact-form" >
      {successAPIMessage && 
        <SuccessMessage message="This is my message." />
      }
      <div className="row gx-20">
        <div className="col-md-6 form-group">          
          <input type="text" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} />
          <i className="fal fa-user"/>
          {nameError && <small className="text-danger">{nameError}</small>}
        </div>
        <div className="col-md-6 form-group">          
          <input type="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
          <i className="fal fa-envelope"/>
          {emailError && <small className="text-danger">{emailError}</small>}
        </div>
        <div className="col-12 form-group">          
          <textarea placeholder="Type Your Message" value={message} onChange={(e) => setMessage(e.target.value)} />
          {messageError && <small className="text-danger">{messageError}</small>}
        </div>
        <div className={`col-12`}>
          <Button onclick={formHandle} type="button" disabled={isSubmitBtnDisabled}> Submit Message<i className="far fa-arrow-right"/></Button>
        </div>
      </div>
    </form>
  );
}

export default FormFive;
