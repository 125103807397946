import React, { Fragment } from 'react';
import {Seo, ScrollTopBtn} from '../components';
import {
  HeaderTwo, 
  HeroOne, 
  FeatureOne, 
  AboutOne, 
  ServiceOne,
  CounterOne,
  TeamOne,
  FaqOne,
  AboutTwo,
  CtaOne,
  ProcessOne,
  TestimonialOne,
  BrandOne,
  BlogTwo,
  FooterOne,
  ContactOne,
  ServiceTwo,
} from '../containers';

const Home = ()=> ( 
  <Fragment>
    <Seo title="Cizby" />
    <HeroOne/>
    <div className="background-image" style={{backgroundImage: "url(public/images/purcased image/Homepage-technology.jpg)"}}>
      <FeatureOne className="space-top space-extra-bottom"/>
    </div>
    <FaqOne/>
    <CtaOne className="space"/>
  </Fragment>
);

export default Home;