import React, { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import { List, ScrollTopBtn, Seo, ServiceBoxOne } from "../../components";

// Service Data
import serviceData from "../../data/service.json";
import FooterOne from "../Footers/FooterOne";
import HeaderTwo from "../Headers/HeaderTwo";
import ServiceSingle from "./ServiceSingle";

const CloudConsultingService = ({ ...restProps }) => (
  <Fragment>
    <Seo title={"Cizby - Cloud Consulting"} />
    <div {...restProps}>
      <br />
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-lg-12">
            <h2 className="h4">Cloud Consulting</h2>
            <p>
            Cloud consulting is a service that helps organizations adopt cloud computing technologies to meet their business goals. It involves advising clients on cloud strategy, architecture, migration, security, cost optimization, and operations. Cloud consultants work with clients to understand their needs, assess their current technology landscape, and recommend the best cloud solutions. The goal of cloud consulting is to help organizations achieve increased scalability, reduced costs, improved security, and increased agility through the use of cloud computing.
            </p>
            <p>
            Cloud consulting services are designed to help organizations maximize the benefits of cloud computing while minimizing the risks and challenges associated with adoption.
            </p>
            <p>
            Cloud consulting is the process where an organization leverages cloud consultants to build, operate, manage and maintain an optimal cloud environment. Cloud consulting accelerates business growth and innovation, drives agility, transforms operational efficiency and ensures performance and security in the cloud.
            </p>
            <p>Cloud consulting services can cover a wide range of topics and are designed to help organizations of all sizes make informed decisions about their cloud computing journey.</p>
            <div style={{ paddingLeft: "30px" }}>
              <List className="list-style3 align-center">
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Cloud Strategy & Planning</b>: A cloud consultant can help organizations create a cloud strategy that aligns with their business goals and objectives. This includes analyzing current IT infrastructure, determining which workloads should be migrated to the cloud, and determining the most cost-effective cloud deployment model.
                  </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Cloud Architecture & Design</b>: Cloud consultants can help organizations design an architecture that meets their specific needs and ensures scalability, security, and reliability. They can also help organizations select the right cloud service providers and services to meet their needs.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Cloud Migration</b>: Cloud consultants can assist with planning, executing, and managing cloud migrations. They can help organizations assess their current IT environment, determine the best approach for migration, and minimize downtime and disruption during the migration process.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Cloud Security</b>: Cloud consultants can help organizations implement security measures to protect their cloud environment. They can assist with security planning, security management, and risk management.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Cloud Cost Optimization</b>: A cloud consultant can help organizations optimize their cloud costs by identifying areas where costs can be reduced and recommending cost-saving strategies.
                </List.Item>
                <List.Item>
                  <i className="fal fa-check-circle" />
                  <b>Cloud Operations & Management</b>: Cloud consultants can help organizations with ongoing cloud operations and management, including monitoring and reporting, capacity planning, and disaster recovery planning.
                </List.Item>
              </List>
            </div>
          </div>
          <div className="row gx-0 mb-4 pb-2 pt-3">
            <div className="col-xl-6"><img src="/images/purchased_image/Img2-cloud-technology.jpg" alt="project" className="w-100"/></div>
            <div className="col-xl-6">
              <div className="service-list-box">
                <h3 className="h5 title">Service Features</h3>
                <List className="list-style3">
                  <List.Item><i className="fal fa-check-circle"/>Seamless integration</List.Item>
                  <List.Item><i className="fal fa-check-circle"/>Scalability</List.Item>
                  <List.Item><i className="fal fa-check-circle"/>Modernized applications</List.Item>
                  <List.Item><i className="fal fa-check-circle"/>Robust governance</List.Item>
                  <List.Item><i className="fal fa-check-circle"/>Cost savings</List.Item>
                  <List.Item><i className="fal fa-check-circle"/>Business continuity</List.Item>
                  <List.Item><i className="fal fa-check-circle"/>Operational efficiency</List.Item>
                  {/* <List.Item><i className="fal fa-check-circle"/>Resiliency and business continuity</List.Item> */}
                </List>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
    <br/>
  </Fragment>
);

export default CloudConsultingService;
